import React from 'react';
import footerData from 'data/Saas/footer.json';
import { Link } from 'gatsby';

const Footer = () => {
  return (
    <footer className="style-1">
      <div className="container">
        <div className="content">
          <div className="row justify-content-center">
            <div className="col-lg-4">
              <div className="foot_info">
                
                <div className="text mb-4">
                We have a value system that is passionately hinged on our professionalism,<br /> ethics, integrity, core values, and superior customer service.


                </div>
                <ul className="mb-4">
                  <li className="d-flex">
                    <i className="bi bi-house me-3"></i>
                    <a href="#">
                      <span> { footerData.address.address1 } <br /> { footerData.address.address2 }</span>
                    </a>
                  </li>
                  <li className="d-flex">
                    <i className="bi bi-envelope me-3"></i>
                    <a href="#">
                      <span> { footerData.email }</span>
                    </a>
                  </li>
                  <li className="d-flex">
                    <i className="bi bi-phone me-3"></i>
                    <a href="#">
                      <span>{ footerData.phone }</span>
                    </a>
                  </li>
                </ul>
                <div className="socail-icons">
              <a href="https://twitter.com/MutualTrustMfb" rel="noreferrer" className="icon-35 rounded-circle bg-gray overflow-hidden d-inline-flex align-items-center justify-content-center text-gray me-2" target="_blank">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://facebook.com/mutualtrustmicrofinancebank" rel="noreferrer" className="icon-35 rounded-circle bg-gray overflow-hidden d-inline-flex align-items-center justify-content-center text-gray me-2" target="_blank">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://instagram.com/mutualtrustmfbltd" rel="noreferrer" className="icon-35 rounded-circle bg-gray overflow-hidden d-inline-flex align-items-center justify-content-center text-gray" target="_blank">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="links">
                <div className="cont">
                  <h6 className="link_title">
                  Useful Links
                  </h6>
                  <ul>
                  {
                footerData.usefulLinks.map((link, index) => (
                  <li key={index}>
                    <Link to={link.link}>{link.title}</Link>
                  </li>
                ))
              }
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="links">
                <div className="cont">
                  <h6 className="link_title">
                  Loans
                  </h6>
                  <ul>
                  {
                footerData.resources.map((link, index) => (
                  <li key={index}>
                    <a href={link.link}>{link.title}</a>
                  </li>
                ))
              }
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="foot_subscribe">
                <h6 className="link_title">
                  Newsletter
                </h6>
                <p>
                  Be the first to know, subscribe to our newsletter
                </p>
                <div className="input-group my-4">
                  <input type="text" className="form-control" placeholder="Enter your email" aria-label="Enter your email" aria-describedby="button-addon2" />
                  <button className="btn butn-gard border-0 text-white px-3" type="button" id="button-addon2">
                    <span>Subscribe</span>
                  </button>
                </div>
                <p className="fst-italic">By subscribing, you accepted the our <a href="#" className="text-decoration-underline"> Policy</a></p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="foot">
              
              <div className="row">
                <div className="col-lg-6">
                  <small className="text-white text-decoration-underline">Developed by Goldencity Media</small>
                </div>
                <div className="col-lg-6">
                  <small className="text-white text-decoration-underline">
                    © 2024 Copyrights.{" "}
                    <a href="#" className="fw-bold text-decoration-underline">
                      MutualTrust MFBank Ltd.
                    </a>{" "}
                    All Rights Reserved
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src="/assets/img/footer/foot_l.png" alt="" className="foot_l" />
      <img src="/assets/img/footer/foot_r.png" alt="" className="foot_r" />
    </footer>
  )
}

export default Footer