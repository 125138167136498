import React from 'react';
import appData from 'data/appData.json';
import Calculator from 'components/App/Calculator';


const Codei = () => {
  return (
    <section className="codei section-padding">
      <div className="container box px-4">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="cont mb-5 mb-lg-0">
             
              <Calculator />
              
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1">
            <div className="img">
           
              <img src="/assets/img/about/b3.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Codei